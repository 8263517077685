import React, {useContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {ParametersContext} from "../../contexts/ParametersContext";
import {useAPI} from "../../hooks/APIHook";
import {useDispatch} from "react-redux";
import {notification} from "../../common/reducer/NotificationReducer";
import {ReactSearchAutocomplete} from 'react-search-autocomplete'

export const DonateComponent = () => {
    const {isMobile} = useContext(ParametersContext);
    const {request} = useAPI();
    const dispatch = useDispatch();

    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        changeReceive().then()
    }, []);

    async function changeReceive() {
        const purse = document.getElementById("purse").value;
        const sum = document.getElementById("sum").value;
        const promocode = document.getElementById("promocode").value || "none";

        const fSum = (await request(`https://api.diamondworld.pro/player/calculate?nickname=${encodeURIComponent(purse)}&promocode=${encodeURIComponent(promocode)}&sum=${sum}&bonus=1`)).sum || 0;

        document.getElementById("receive").value = `${fSum}₽`;
    }

    const fetchSuggestions = async (nickname) => {
        if (!nickname) {
            setSearchResults([]);
            return;
        }
        try {
            const suggestions = (await request(`https://api.diamondworld.pro/player/suggestion?nickname=${encodeURIComponent(nickname)}`)).suggestion
            setSearchResults(suggestions);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        } finally {
        }
    };

    useEffect(() => {
        if (searchQuery.trim().length > 0) {
            fetchSuggestions(searchQuery).then();
        } else {
            setSearchResults([]);
        }
    }, [searchQuery]);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSelect = (item) => {
        setSearchQuery(item);
    };

    function blockPattern(e, pattern) {
        if (pattern.test(e.key) && e.key !== "Backspace" && !e.key.includes("Arrow")) e.preventDefault();

        setTimeout(async () => {
            e.target.value = e.target.value.replace(pattern, "");

            await changeReceive();
        }, 100);
    }

    function blockNickname(e) {
        blockPattern(e, /\W/);
    }

    function blockPromocode(e) {
        blockPattern(e, /[\W_]/);
    }

    function blockAmount(e) {
        blockPattern(e, /[^0-9]/);
    }

    async function handleForm(e) {
        const form = document.querySelectorAll("form")[0];
        e.preventDefault();
        const exists = (await request(`https://api.diamondworld.pro/player/exists?nickname=${encodeURIComponent(form.elements[0].value)}`)).exists || false
        if (!exists) {
            dispatch(notification({message: "Такой аккаунт не зарегистрирован"}));
            return
        }
        form.action = `https://api.diamondworld.pro/${e.target.id}/create`;

        if (e.target.id === "freekassa" || e.target.id === "freekassa2")
            if (form.elements[3].value < 50) {
                dispatch(notification({message: "Минимальная сумма платежа: 50 рублей"}));
                return
            }

        if (e.target.id === "freekassacards")
            if (form.elements[3].value < 10) {
                dispatch(notification({message: "Минимальная сумма платежа: 10 рублей"}));
                return
            }

        form.submit()
    }

    return (
        <>
            <Helmet title="Пополнение баланса < DiamondWorld"/>
            <div className="donate">
                {isMobile ? <></> :
                    <div className="information">
                        <div>
                            DiamondWorld<br/>
                            Minecraft сервер
                        </div>
                        <div>
                            Здесь вы можете пополнить свой баланс, который используется для покупки товаров на сервере
                        </div>
                    </div>
                }

                <div>
                    <div>
                        <div>Бонусы!</div>
                        Пополнение от 500р - бонус 5%<br/>
                        Пополнение от 3000р - бонус 10%<br/>
                        <br/>
                        При проблемах с пополнением через VISA попробуйте карту MASTERCARD или другой способ оплаты<br/>
                        <br/>
                        Для пополнения через украинскую карту создайте заявку <a href="https://t.me/PayMentsDW_bot"
                                                                                 target="_blank"
                                                                                 rel="noreferrer">в боте</a> (график
                        работы менеджера бота с 10:00 до 22:00 МСК).<br/>
                    </div>

                    <form>
                        <div className="input-group">
                            <label htmlFor="purse">Никнейм:</label>
                            <input name="purse" id="purse" placeholder="Введите ник" type="text" required
                                   onKeyDown={blockNickname}/>
                        </div>

                        <div className="input-group">
                            <label htmlFor="username">Комментарий к платежу:</label>
                            <input name="username" id="username" placeholder="Комментарий к платежу (если нужен)"
                                   type="text" onKeyDown={blockNickname}/>
                        </div>

                        <div className="input-group">
                            <label htmlFor="promocode">Промокод:</label>
                            <small>Большие буквы кода - обязательны!</small>
                            <input name="promocode" id="promocode" placeholder="Введите промокод (если есть)"
                                   type="text" onKeyDown={blockPromocode}/>
                        </div>

                        <div className="input-flex">
                            <div className="input-group">
                                <label htmlFor="sum">Потратите:</label>
                                <input name="sum" id="sum" type="text" placeholder="Сумма" defaultValue="500" required
                                       onKeyDown={blockAmount}/>
                            </div>
                            <div className="input-group">
                                <label htmlFor="receive">Получите:</label>
                                <input id="receive" type="text" placeholder="500₽" disabled/>
                            </div>
                        </div>

                        <button className="btn" id="enot" type="submit" onClick={handleForm}>Оплата СБП (без комиссии)
                        </button>
                        <button className="btn" id="freekassacards" type="submit" onClick={handleForm}>Оплата картой РФ
                        </button>
                        <button className="btn" id="freekassa" type="submit" onClick={handleForm}>Оплата другим способом
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}
